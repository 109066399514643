import React, { Fragment } from "react";
import ShortLinkInterstitial from "components/ShortLinkInterstitial";
import SmallErrorNotice from "components/SmallErrorNotice";
import Head from "components/Head";
import { useExchangePayContext } from "utils/context/pay";
import { Elements } from "@stripe/react-stripe-js";

const ExchangePayContainer = (props) => {
  const payCtx = useExchangePayContext();

  let mainContent = null;
  let headProps = {};
  if (payCtx) {
    const {
      stripePromise,
      hasStripeClientKey,
      capabilities,
      shortLink,
      error,
      content,
      metadata
    } = payCtx;

    if (error) {
      mainContent = (
        <div className="w-100 h-100 flex flex-column items-center justify-center tc">
          <SmallErrorNotice {...content} />
        </div>
      );
    } else if (hasStripeClientKey && stripePromise) {
      mainContent = (
        <Elements stripe={stripePromise}>{props.children}</Elements>
      );
    } else {
      mainContent = (
        <ShortLinkInterstitial
          capabilities={capabilities}
          shortLink={shortLink}
        />
      );
    }

    headProps = metadata || {};
  }

  return (
    <Fragment>
      <Head {...headProps} />
      {mainContent}
    </Fragment>
  );
};

export default ExchangePayContainer;
