import React from "react";
import ExchangePayContainer from "components/ExchangePayContainer";
import { ExchangePayContextProvider } from "utils/context/pay";
import ExchangePay from "components/ExchangePay";

const ExchangePayWrapper = (props) => (
  <ExchangePayContextProvider {...props}>
    <ExchangePayContainer>
      <ExchangePay />
    </ExchangePayContainer>
  </ExchangePayContextProvider>
);

export default ExchangePayWrapper;
