import React, { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { checkoutPay } from "utils/checkout/actions";
import SmallErrorNotice from "components/SmallErrorNotice";
import ShortLinkInterstitial from "components/ShortLinkInterstitial";
import { useExchangePayContext } from "utils/context/pay";
import { formFactorAction } from "utils/view";
import Button from "components/Form/fields/Button";
import { UI_THEME } from "utils/constants/ui";
import { useTimedOut } from "utils/hooks/setTimeout";

const ExchangePay = () => {
  const {
    capabilities,
    shortLink,
    config,
    stripeStatus
  } = useExchangePayContext();
  const stripe = useStripe();
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const [content, setContent] = useState(null);
  const timedOut = useTimedOut();

  useEffect(() => {
    if (stripe && config.requestUrl) {
      checkoutPay({
        stripe,
        setLoading,
        setError,
        setContent,
        url: config.requestUrl,
        headers: config.headers
      });
    }
  }, [stripe, config.requestUrl]);

  let mainContent;

  if (error) {
    mainContent = (
      <SmallErrorNotice
        title="Error"
        body={["There was an error initiating payment", error.message]}
      />
    );
  } else if (content) {
    mainContent = <SmallErrorNotice {...content} />;
  } else if (stripeStatus.isRejected || timedOut) {
    mainContent = (
      <SmallErrorNotice
        title="Error"
        body={[
          timedOut
            ? "Checkout settings took too long to load."
            : "Checkout settings for this payment failed to load.",
          `${formFactorAction()} refresh to try again.`
        ]}
      >
        <div className="pt2">
          <Button
            {...{
              rel: "noopener noreferrer",
              loading: refreshing,
              onClick: () => {
                setRefreshing(true);

                const { href } = window.location;
                window.location.href = href;
              },
              theme: UI_THEME.SLIM,
              copy: "Refresh",
              tag: "a"
            }}
          />
        </div>
      </SmallErrorNotice>
    );
  } else if (loading || !stripeStatus.isFulfilled) {
    mainContent = (
      <ShortLinkInterstitial
        capabilities={capabilities}
        shortLink={shortLink}
      />
    );
  }

  return (
    <div className="w-100 h-100 flex flex-column items-center justify-center">
      {mainContent}
    </div>
  );
};

export default ExchangePay;
