import React from "react";
import LoadingSpinner from "components/LoadingSpinner";
import { getIconForUID } from "components/FormerEditor/utils";
import { COLORS } from "utils/styles";

const FullSpinner = ({ icon, title, children }) => {
  const Icon = icon && getIconForUID(icon);

  return (
    <div className="w-100 min-vh-100 flex flex-column items-center justify-center">
      {Icon && <Icon size={30} color={COLORS.blue} />}
      {title && <div className="f6 pt1 pb1">{title}</div>}
      {children}
      <div className="pt1">
        <LoadingSpinner customClasses={{ ring: "blue" }} />
      </div>
    </div>
  );
};
export default FullSpinner;
