import { getIconForUID } from "components/FormerEditor/utils";
import { useRouter } from "next/router";
import React from "react";
import { COLORS } from "utils/styles";

const SmallErrorNotice = ({ title, body, actions, children }) => {
  const Icon = getIconForUID("alert");

  const { query } = useRouter();
  const errorActions = actions && actions.length ? actions : [];
  /**
   * Add a Back if theres a query string param for it and there are no other error actions
   */
  if (!errorActions.length && query && query.cancel_url) {
    errorActions.push({
      href: query.cancel_url,
      copy: "Back"
    });
  }
  const actionNodes = errorActions.map(({ copy, href, target }, actionIx) => (
    <a
      className="pt1 dib link pointer link__decoration--hover f6 color-primary fw5"
      key={actionIx}
      target={target || "_self"}
      rel="noreferrer noopener"
      href={href}
    >
      {copy}
    </a>
  ));

  return (
    <div className="w-100 flex flex-column items-center justify-center tc ph3">
      {(title || body) && <Icon size={20} color={COLORS.primary} />}
      <div className="f5 pt2 fw5">{title}</div>
      {body &&
        body.map((bodyCopy, bodyCopyIx) => (
          <div
            style={{ maxWidth: 360 }}
            className="f6 pt1 lh-copy"
            key={bodyCopyIx}
          >
            {bodyCopy}
          </div>
        ))}
      {actionNodes}
      {children}
    </div>
  );
};

export default SmallErrorNotice;
