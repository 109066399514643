import React from "react";
import { ICON_DIMENSIONS } from "components/Checkout/BrandIconHeader";

const IMAGE_STYLE = {
  maxWidth: "100%",
  maxHeight: "100%"
};

const ContentBusinessIconHeader = ({ icon, logo, domain = "" }) => {
  let content = null;
  let alt = `Brand logo for ${domain}`;

  if (icon) {
    content = (
      <div
        style={{
          height: ICON_DIMENSIONS,
          width: ICON_DIMENSIONS
        }}
        className="br-100 hairline-1 ba pa2 overflow-hidden flex items-center justify-center"
      >
        <img alt={alt} style={IMAGE_STYLE} src={icon} />
      </div>
    );
  } else if (logo) {
    content = (
      <div
        style={{
          height: ICON_DIMENSIONS
        }}
        className="overflow-hidden"
      >
        <img alt={alt} style={IMAGE_STYLE} src={logo} />
      </div>
    );
  }
  return content;
};

export default ContentBusinessIconHeader;
