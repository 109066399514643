import { useState, useEffect } from "react";

const TEN_SECONDS = 10000;
export const useTimedOut = (timeout = TEN_SECONDS) => {
  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setTimedOut(true), timeout);
    return () => clearTimeout(timer);
  }, []);

  return timedOut;
};
