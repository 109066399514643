import React from "react";
import Link from "next/link";
import { useContentQueryParams } from "utils/hooks";
import classnames from "classnames";

const ContentFooterLinks = ({ domain }) => {
  const { isPlatformPageSource, hasReturnTo, query } = useContentQueryParams();
  const isLink = isPlatformPageSource || hasReturnTo;
  const domainLink = `https://${domain}`;

  return (
    <ul className="flex ma0 pa0 list items-center pv3">
      {hasReturnTo && (
        <li className="di">
          <Link href={query.return_to}>
            <a className="f6 link color-primary fw5 link__decoration--hover">
              Return
            </a>
          </Link>
        </li>
      )}
      {domain && (
        <li
          className={classnames("di fw6", {
            "auth__footerLink--dot": isLink
          })}
        >
          <a
            href={domainLink}
            className="f6 link color-primary fw5 link__decoration--hover"
          >
            {domainLink}
          </a>
        </li>
      )}
    </ul>
  );
};
export default ContentFooterLinks;
