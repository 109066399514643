import React, { Fragment } from "react";
import PropTypes from "prop-types";
import SupportSection from "components/Checkout/SupportSection";
import classnames from "classnames";
import { CELEBRATE, CELEBRATE_MESSAGES } from "utils/constants/celebrate";
import HeaderContent from "components/Checkout/HeaderContent";
import ContentDownloadList from "components/ContentDownloadList";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import compact from "lodash/compact";
import ContentReferenceSection from "components/ContentReferenceSection";
import ContentFooterLinks from "components/ContentFooterLinks";
import dynamic from "next/dynamic";
import ContentBusinessIconHeader from "components/ContentBusinessIconHeader";
import PageSplitContainer from "components/PageSplitContainer";
const CelebrateLoader = () => <span></span>;
const Celebrate = dynamic(() => import("components/Celebrate"), {
  ssr: false,
  loading: CelebrateLoader
});

const CheckoutContent = ({
  account,
  organization,
  attachments,
  content,
  hideCelebrate
}) => {
  const businessIcon = account && account.businessIcon;
  const businessLogo = account && account.businessLogo;
  const hasBrandAsset = Boolean(businessIcon || businessLogo);
  const domain = get(organization, "domain");
  const hasAccount = isEmpty(compact(Object.values(account)));

  const right = (
    <Fragment>
      {hasBrandAsset && (
        <ContentBusinessIconHeader
          icon={businessIcon}
          logo={businessLogo}
          domain={domain}
        />
      )}
      <div>
        <div
          className={classnames("w-100 pb3-l", {
            pt3: hasBrandAsset
          })}
        >
          {content && (
            <HeaderContent
              title={content.title}
              body={content.body}
              footer={content.footer}
            />
          )}
          <SupportSection
            customClasses={{
              container: "mt3 pv3 bt hairline-1 f6 lh-copy"
            }}
            url={account && account.supportUrl}
            email={account && account.supportEmail}
            phone={account && account.supportPhone}
          />
          {content && content.reference && content.reference.value && (
            <ContentReferenceSection
              hasAccount={hasAccount}
              content={content}
            />
          )}
          <ContentFooterLinks domain={domain} />
        </div>
      </div>
    </Fragment>
  );

  const left = <ContentDownloadList data={attachments} />;
  const contentProps = {
    left,
    right,
    hasBrandingAssets: hasBrandAsset,
    customClasses: {
      left:
        "bt hairline-1 bn-ns mt3 pt3 mt0-ns pt0-ns order-1 order-0-ns pb5 pb0-ns",
      right: "order-0 order-1-ns"
    }
  };

  return (
    <Fragment>
      {!hideCelebrate && (
        <Celebrate
          hideToast
          message={CELEBRATE_MESSAGES[CELEBRATE.CONTENT_DOWNLOAD]}
        />
      )}
      <PageSplitContainer {...contentProps} />
    </Fragment>
  );
};

CheckoutContent.propTypes = {
  account: PropTypes.object,
  content: PropTypes.object,
  organization: PropTypes.object,
  items: PropTypes.object,
  session: PropTypes.object,
  subscription: PropTypes.object
};

export default CheckoutContent;
