import React from "react";
import FullSpinner from "components/FullSpinner";
import PoweredBy from "components/PoweredBy";
import { getProductFeatureEnabled } from "utils/capability";
import { PRODUCT_FEATURE_CAPABILITY } from "utils/constants/capability";
import { useIntervalValue } from "utils/hooks";
import {
  getInterstitialTitleCollection,
  getInterstitialIcon
} from "utils/shortLink";

const PayInterstitial = ({
  capabilities,
  icon,
  titleCollection,
  shortLink
}) => {
  const ctxIcon = icon || getInterstitialIcon(shortLink);
  const ctxTitleCollection =
    titleCollection || getInterstitialTitleCollection(shortLink);
  const title = useIntervalValue(ctxTitleCollection);
  const showBranding = !getProductFeatureEnabled(
    capabilities,
    PRODUCT_FEATURE_CAPABILITY.REMOVE_BRANDING
  );

  const brand = showBranding ? (
    <div className="pv1">
      <PoweredBy />
    </div>
  ) : null;

  return (
    <FullSpinner icon={ctxIcon} title={title}>
      {brand}
    </FullSpinner>
  );
};

export default PayInterstitial;
