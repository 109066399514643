import React from "react";
import CopyToClipboard from "components/CopyToClipboard";
import classnames from "classnames";

const ContentReferenceSection = ({ hasAccount, content }) => (
  <div
    className={classnames("flex flex-column w-100 pv3", {
      "mt3 bt hairline-1": !hasAccount
    })}
  >
    <div className="f6 pb2">{content.reference.title}</div>
    <div className="flex flex-row items-center">
      <div className="dib">
        <CopyToClipboard
          value={content.reference.value}
          toast={content.reference.toast}
          tooltip={content.reference.tooltip}
        />
      </div>
      <div className="token-monospace f6 lh-copy word-break-all pa2 bg-content--secondary ba br2 hairline-1">
        {content.reference.value}
      </div>
    </div>
  </div>
);

export default ContentReferenceSection;
