import React, { Fragment } from "react";
import PropTypes from "prop-types";
import SupportSection from "components/Checkout/SupportSection";
import classnames from "classnames";
import { CELEBRATE, CELEBRATE_MESSAGES } from "utils/constants/celebrate";
import { BUTTON_CLASS_CONTEXT, BUTTON_TAGS } from "utils/constants/ui";
import CheckoutPreview from "components/CheckoutPreview";
import ContentBusinessIconHeader from "components/ContentBusinessIconHeader";
import HeaderContent from "components/Checkout/HeaderContent";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
import get from "lodash/get";
import ContentReferenceSection from "components/ContentReferenceSection";
import ContentFooterLinks from "components/ContentFooterLinks";
import Button from "components/Form/fields/Button";
import dynamic from "next/dynamic";
import PageSplitContainer from "components/PageSplitContainer";
import LegalEditorField from "components/LegalEditorField";
import { CONTENT_FORMAT_TYPE } from "constants/content";

const CelebrateLoader = () => <span></span>;
const Celebrate = dynamic(() => import("components/Celebrate"), {
  ssr: false,
  loading: CelebrateLoader
});

const CheckoutSuccess = ({
  account,
  organization,
  checkout,
  collapseImages,
  content,
  hideCelebrate
}) => {
  const businessIcon = account && account.businessIcon;
  const businessLogo = account && account.businessLogo;
  const hasBrandAsset = Boolean(businessIcon || businessLogo);
  const domain = get(organization, "domain");
  const hasAccount = !isEmpty(compact(Object.values(account)));

  let contentSection = null;
  if (content && content.value) {
    const contentValue = content.value;

    if (content.formatType === CONTENT_FORMAT_TYPE.RICH) {
      contentSection = (
        <LegalEditorField
          content={contentValue}
          config={{
            paragraph: {
              HTMLAttributes: {
                class: "f5 lh-copy pt2 mv0"
              }
            },
            heading: {
              HTMLAttributes: {
                class: "f2 fw6 mv0"
              }
            }
          }}
        />
      );
    } else {
      contentSection = (
        <Fragment>
          <HeaderContent
            title={contentValue.title}
            body={contentValue.body}
            footer={contentValue.footer}
          />
          {content.returnTo && (
            <div className="mw5 pt3">
              <Button
                href={content.returnTo.url}
                copy={content.returnTo.copy}
                tag={BUTTON_TAGS.ANCHOR}
                // target="_blank"
                classes={{
                  classes: {
                    contextClass: BUTTON_CLASS_CONTEXT.BLOCKS_PRIMARY
                  }
                }}
              />
            </div>
          )}
          <SupportSection
            customClasses={{
              container: "mt3 pv3 bt hairline-1 f6 lh-copy"
            }}
            url={account && account.supportUrl}
            email={account && account.supportEmail}
            phone={account && account.supportPhone}
          />
        </Fragment>
      );
    }
  }

  const right = (
    <Fragment>
      {hasBrandAsset && (
        <ContentBusinessIconHeader
          icon={businessIcon}
          logo={businessLogo}
          domain={domain}
        />
      )}
      <div>
        <div
          className={classnames("w-100 pb3-l", {
            pt3: hasBrandAsset
          })}
        >
          {contentSection}
          {content && content.reference && content.reference.value && (
            <ContentReferenceSection
              hasAccount={hasAccount}
              content={content}
            />
          )}
          <ContentFooterLinks domain={domain} />
        </div>
      </div>
    </Fragment>
  );

  const left = (
    <CheckoutPreview collapseImages={collapseImages} {...checkout} />
  );

  const contentProps = {
    left,
    right,
    hasBrandingAssets: hasBrandAsset,
    customClasses: {
      left:
        "bt hairline-1 bn-ns mt3 pt3 mt0-ns pt0-ns order-1 order-0-ns pb5 pb0-ns",
      right: "order-0 order-1-ns"
    }
  };

  return (
    <Fragment>
      {!hideCelebrate && (
        <Celebrate
          hideToast
          message={CELEBRATE_MESSAGES[CELEBRATE.BUILDER_PAYMENT_LINK]}
        />
      )}
      <PageSplitContainer {...contentProps} />
    </Fragment>
  );
};

CheckoutSuccess.propTypes = {
  account: PropTypes.object,
  content: PropTypes.object,
  organization: PropTypes.object,
  items: PropTypes.object,
  session: PropTypes.object,
  subscription: PropTypes.object
};

export default CheckoutSuccess;
