import React, { useState } from "react";
import axios from "axios";
import Button from "components/Form/fields/Button";
import { useRouter } from "next/router";
import { getListBorders, UI_THEME } from "utils/constants/ui";
import { captureException } from "utils/error";
import { useToasts } from "react-toast-notifications";
import { getMimeTypeLabel } from "utils/attachment";
import SlimCTA from "components/SlimCTA";
import { getIconForUID } from "components/FormerEditor/utils";
import { COLORS } from "utils/styles";
const DOWNLOAD_MAX = 3;

const DownloadListItem = ({ url, filename, mimetype, size }) => {
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(0);
  const {
    query: { content }
  } = useRouter();

  const downloadAsset = async () => {
    setLoading(true);

    try {
      const { data } = await axios({
        url,
        method: "post",
        data: {
          content
        }
      });
      if (data.url) {
        window.location.href = data.url;
        const updatedCount = counter + 1;
        setCounter(updatedCount);
      }
    } catch (err) {
      captureException(err);
      addToast(
        "That link is expired. Refresh this link by clicking the download link in the email purchase receipt you received.",
        {
          appearance: "error",
          autoDismiss: false
        }
      );
    }
    setLoading(false);
  };
  const Icon = getIconForUID("download");
  const disabled = loading || counter === DOWNLOAD_MAX;
  return (
    <div className="link w-100 flex flex-row">
      <div
        className="bg-black-05 color-text--secondary items-center justify-center flex br2"
        style={{ height: 40, width: 40 }}
      >
        <div className="f6 fw6">{getMimeTypeLabel(mimetype)}</div>
      </div>
      <div className="flex flex-row justify-between w-100 items-start">
        <div className="flex flex-column w-100 pl3">
          <div className="f5 pb1 fw5 pr2 word-break">{filename}</div>
          <div className="f7">{size}</div>
        </div>
        <div className="flex flex-shrink-0">
          <Button
            onClick={downloadAsset}
            tag="button"
            theme={UI_THEME.SLIM}
            disabled={disabled}
            loading={loading}
          >
            <div className="pt1">
              <Icon
                size={20}
                color={disabled ? COLORS.secondary : COLORS.white}
              />
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

const ContentDownloadList = ({ data }) => {
  return (
    <div className="w-100">
      <h3 className="f4 fw6">Files</h3>
      {data.length > 0 ? (
        <ul className="w-100 pa0 ma0 list">
          {data.map(({ url, filename, mimetype, size }, attachIx) => {
            const isFirst = attachIx === 0;
            const isLast = data.length - 1 === attachIx;

            return (
              <li
                key={attachIx}
                className={getListBorders({
                  className: "w-100 hairline-1 bl br pa3 dib bg-white",
                  isFirst,
                  isLast
                })}
              >
                <DownloadListItem
                  url={url}
                  filename={filename}
                  mimetype={mimetype}
                  size={size}
                />
              </li>
            );
          })}
        </ul>
      ) : (
        <SlimCTA subheader="No files available." />
      )}
    </div>
  );
};

export default ContentDownloadList;
