import React, { useEffect } from "react";
import CheckoutSuccess from "components/CheckoutSuccess";
import MadeWith from "components/MadeWith";
import Head from "components/Head";
import { PageFactoryContextProvider } from "utils/context";
import { PRODUCT_FEATURE_CAPABILITY } from "utils/constants/capability";
import { getProductFeatureEnabled } from "utils/capability";
import { getPageFactoryFormTracking } from "utils/tracking";
import CheckoutContent from "components/CheckoutContent";
import { usePageEditMode } from "utils/hooks";
import BillingManageStatus from "components/BillingManageStatus";
import PageFactoryForm from "components/PageFactoryForm";
import ExchangeShortLinkWrapper from "components/Exchange/ShortLink/Standard";
// Server Fns
import {
  SERVER_GENERIC_ERROR,
  SERVER_PARAMS_MISSING_ERROR
} from "utils/constants/error";
import { getServerRequestParams, isMalformedValue } from "utils/server";
import { getErrorServerSideProps } from "utils/server/error";
import { getCheckoutSuccessServerSideProps } from "utils/server/checkout/success";
import { getCheckoutContentServerSideProps } from "utils/server/checkout/content";
import { getPageServerSideProps } from "utils/server/page";
import { getBillingManageStatusServerSideProps } from "utils/server/billing";
import { getLinkServerSideProps } from "utils/server/link";
import { ROUTE_CONTEXT } from "utils/constants/server";
import { useEmbedEnabled } from "utils/hooks/embed";
import CheckoutInfoNotice from "components/Checkout/InfoNotice";
import FullSpinner from "components/FullSpinner";
import { STATE_KEYS } from "utils/constants/state";
import get from "lodash/get";
import { getPayServerSideProps } from "utils/server/pay";
import ExchangePayWrapper from "components/Exchange/Pay/Standard";
import ErrorNotice from "components/ErrorNotice";
import SmallErrorNotice from "components/SmallErrorNotice";

const AliasPage = ({ error, ...props }) => {
  let pageContent;
  let showBranding;
  const metadata = props.metadata;
  const context = props.config && props.config.context;
  const { editable } = usePageEditMode();
  const embedEnabled = useEmbedEnabled();

  const redirectTo = props.redirectTo;
  useEffect(() => {
    if (redirectTo) {
      window.location.href = redirectTo;
    }
  }, [redirectTo]);

  if (error) {
    if (context === ROUTE_CONTEXT.LINK || context === ROUTE_CONTEXT.PAY) {
      pageContent = (
        <div className="vh-100 flex items-center justify-center">
          <SmallErrorNotice {...props.content} />
        </div>
      );
    } else {
      pageContent = (
        <div className="w-100 measure center relative mt5 pa3">
          {props.content ? (
            <CheckoutInfoNotice
              account={props.account}
              content={props.content}
            />
          ) : (
            <ErrorNotice error={error} />
          )}
        </div>
      );
    }
  } else if (redirectTo) {
    pageContent = <FullSpinner title="Loading..." />;
  } else if (context === ROUTE_CONTEXT.LINK) {
    /**
     * Note:
     * - setup and field links will use the standard exchange wrapper
     * - and then redirect to the appropriate route
     * - we dont need to add individual / subset context handler cases for them here
     * - If there query string params they will be encrypted and set on a session query string param to be passed in successive requests
     */
    pageContent = (
      <div className="vh-100">
        <ExchangeShortLinkWrapper {...props} />
      </div>
    );
  } else if (context === ROUTE_CONTEXT.PAY) {
    pageContent = (
      <div className="vh-100">
        <ExchangePayWrapper {...props} />
      </div>
    );
  } else if (
    context === ROUTE_CONTEXT.CHECKOUT_SUCCESS ||
    context === ROUTE_CONTEXT.CHECKOUT_ERROR ||
    context === ROUTE_CONTEXT.CHECKOUT_COMPLETED
  ) {
    pageContent = <CheckoutSuccess {...props} />;
  } else if (context === ROUTE_CONTEXT.CHECKOUT_CONTENT) {
    pageContent = <CheckoutContent {...props} />;
  } else if (context === ROUTE_CONTEXT.BILLING_MANAGE_STATUS) {
    pageContent = <BillingManageStatus {...props} />;
  } else if (context === ROUTE_CONTEXT.PAGE && props.manifest) {
    const {
      manifest,
      capabilities,
      /**
       * Note: config is the response shape of getServerRequestParams
       */
      config
    } = props;

    showBranding = !getProductFeatureEnabled(
      capabilities,
      PRODUCT_FEATURE_CAPABILITY.REMOVE_BRANDING
    );

    pageContent = (
      <PageFactoryContextProvider admin={get(props, STATE_KEYS.MANIFEST.ADMIN)}>
        <PageFactoryForm
          {...manifest}
          tracking={getPageFactoryFormTracking({ manifest, config })}
        />
      </PageFactoryContextProvider>
    );
  } else {
    pageContent = (
      <div className="w-100 measure center relative mt5 pa3">
        <ErrorNotice
          hideActions
          error={{ body: ["An unexpected error ocurred."] }}
        />
      </div>
    );
  }
  const headProps = metadata || {};
  const showMadeWith = (showBranding || editable) && !embedEnabled;

  return (
    <div className="w-100">
      <Head {...headProps} />
      {showMadeWith && <MadeWith />}
      {pageContent}
    </div>
  );
};

/**
 * When a page is requested without any parameters then the default object {} with be encoded
 * Resulting in %5Bobject%20Object%5D" or [object Object]
 * If present - we then return standard error content
 */
export const getServerSideProps = async ({ req }) => {
  const url = req.url;
  if (isMalformedValue(url)) {
    return SERVER_GENERIC_ERROR;
  }

  const params = getServerRequestParams({ request: req });
  let result;
  const input = {
    apiKey: process.env.PRICE_BLOCS_PLATFORM_KEY,
    params
  };

  console.log(`getServerRequestParams`, JSON.stringify(params, null, 2));
  if (params.context === ROUTE_CONTEXT.LINK) {
    result = await getLinkServerSideProps(input);
  } else if (params.context === ROUTE_CONTEXT.PAY) {
    result = await getPayServerSideProps(input);
  } else if (params.context === ROUTE_CONTEXT.CHECKOUT_SUCCESS) {
    result = await getCheckoutSuccessServerSideProps(input);
  } else if (params.context === ROUTE_CONTEXT.CHECKOUT_COMPLETED) {
    // TODO: Fetch and render completed messaging
    result = await getErrorServerSideProps(input);
  } else if (params.context === ROUTE_CONTEXT.CHECKOUT_ERROR) {
    result = await getErrorServerSideProps(input);
  } else if (params.context === ROUTE_CONTEXT.CHECKOUT_CONTENT) {
    result = await getCheckoutContentServerSideProps(input);
  } else if (params.context === ROUTE_CONTEXT.BILLING_MANAGE_STATUS) {
    result = await getBillingManageStatusServerSideProps(input);
  } else if (params.context === ROUTE_CONTEXT.PAGE && params.minPageParams) {
    result = await getPageServerSideProps(input);
  } else if (!params.minPageParams) {
    result = SERVER_PARAMS_MISSING_ERROR;
  } else {
    result = SERVER_GENERIC_ERROR;
  }

  return result;
};

export default AliasPage;
