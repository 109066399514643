import {
  getLeftSplitStyles,
  SPLIT_CONTENT_MAX_WIDTH
} from "utils/constants/ui";
import { getPageSplitContainerClasses } from "utils/ui";

const PageSplitContainer = (props = {}) => {
  const classes = getPageSplitContainerClasses(props);

  return (
    <div className={classes.container}>
      <div style={{ zIndex: -1 }} className={classes.background}>
        <div className="w-50 bg-off-white-ns"></div>
        <div className="w-50 split__container-ns bg-white"></div>
      </div>
      <div
        style={{ maxWidth: SPLIT_CONTENT_MAX_WIDTH }}
        className={classes.content}
      >
        <div
          style={getLeftSplitStyles({
            hasBrandingAssets: props.hasBrandingAssets
          })}
          className={classes.left}
        >
          {props.left}
        </div>
        <div className={classes.right}>{props.right}</div>
      </div>
    </div>
  );
};

export default PageSplitContainer;
