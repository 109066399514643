import React, { Fragment } from "react";
import PropTypes from "prop-types";
import SupportSection from "components/Checkout/SupportSection";
import classnames from "classnames";
import { MAIN_CONTENT_MAX_WIDTH } from "utils/constants/ui";
import CheckoutPreview from "components/CheckoutPreview";
import Padder from "components/Padder";
import HeaderContent from "components/Checkout/HeaderContent";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
import get from "lodash/get";
import ContentBusinessIconHeader from "components/ContentBusinessIconHeader";
import ContentReferenceSection from "components/ContentReferenceSection";
import ContentFooterLinks from "components/ContentFooterLinks";

const BillingManageStatus = ({
  account,
  organization,
  checkout,
  collapseImages,
  content
}) => {
  const businessIcon = account && account.businessIcon;
  const businessLogo = account && account.businessLogo;
  const hasBrandAsset = Boolean(businessIcon || businessLogo);
  const domain = get(organization, "domain");

  const hasAccount = isEmpty(compact(Object.values(account)));

  return (
    <Fragment>
      <div className={MAIN_CONTENT_MAX_WIDTH}>
        <div className="flex flex-column fl h-100 relative w-100 ph3 pb3 pt0 pt3-l">
          <div className="w-100 pt5">
            <div className="flex flex-column flex-row-l">
              <div className="w-100 w-50-l pa2 pa4-l flex flex-column">
                {hasBrandAsset && (
                  <ContentBusinessIconHeader
                    icon={businessIcon}
                    logo={businessLogo}
                    domain={domain}
                  />
                )}
                <div>
                  <div
                    className={classnames("w-100 pb3-l", {
                      pt3: hasBrandAsset
                    })}
                  >
                    {content && (
                      <HeaderContent
                        title={content.title}
                        body={content.body}
                        footer={content.footer}
                      />
                    )}
                    <SupportSection
                      customClasses={{
                        container: "mt3 pv3 bt hairline-1 f6 lh-copy"
                      }}
                      url={account && account.supportUrl}
                      email={account && account.supportEmail}
                      phone={account && account.supportPhone}
                    />
                    {content &&
                      content.reference &&
                      content.reference.value && (
                        <ContentReferenceSection
                          hasAccount={hasAccount}
                          content={content}
                        />
                      )}
                    <ContentFooterLinks domain={domain} />
                  </div>
                </div>
              </div>
              <div className="w-100 w-50-l">
                <div
                  className="w-100 pt4 pa3 pa4-l ba br2 hairline-1"
                  style={{ backgroundColor: "#FCFCFC" }}
                >
                  <CheckoutPreview
                    collapseImages={collapseImages}
                    {...checkout}
                  />
                </div>
              </div>
            </div>
          </div>
          <Padder />
        </div>
        <Padder theme="visible" />
      </div>
    </Fragment>
  );
};

BillingManageStatus.propTypes = {
  account: PropTypes.object,
  content: PropTypes.object,
  organization: PropTypes.object,
  items: PropTypes.object,
  session: PropTypes.object,
  subscription: PropTypes.object
};

export default BillingManageStatus;
