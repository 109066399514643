/* eslint-disable */
import React from "react";
import { useStripeStatusPromise } from "utils/hooks/stripe";
import { createUseContext } from ".";

export const {
  ContextProvider: ExchangePayContextProvider,
  useContext: useExchangePayContext
} = createUseContext(
  (Provider) => ({
    children,
    admin,
    error,
    organization,
    account,
    config,
    capabilities,
    content,
    metadata,
    pay
  }) => {
    const clientKey = admin && admin.clientKey;

    const {
      stripePromise,
      stripeStatus,
      hasStripeClientKey
    } = useStripeStatusPromise(clientKey);

    return (
      <Provider
        value={{
          hasStripeClientKey,
          stripePromise,
          stripeStatus,
          organization,
          config,
          admin,
          account,
          capabilities,
          content,
          error,
          metadata,
          pay
        }}
      >
        {children}
      </Provider>
    );
  }
);
