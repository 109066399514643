import React from "react";
import ExchangeShortLinkContainer from "components/ExchangeShortLinkContainer";
import { ExchangeShortLinkContextProvider } from "utils/context";
import ExchangeShortLink from "components/ExchangeShortLink";

const ExchangeShortLinkWrapper = (props) => (
  <ExchangeShortLinkContextProvider {...props}>
    <ExchangeShortLinkContainer>
      <ExchangeShortLink />
    </ExchangeShortLinkContainer>
  </ExchangeShortLinkContextProvider>
);

export default ExchangeShortLinkWrapper;
